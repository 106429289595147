<template>
	<div class="bg-white overflow-hidden shadow rounded-lg">
		<div class="bg-white shadow overflow-hidden  sm:rounded-lg">
			<div class="px-4 py-5 border-b border-gray-200 sm:px-6">
				<h3 class="text-lg leading-6 font-medium text-gray-900">
					License Information
				</h3>
				<p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
					Current License Status.
				</p>
			</div>
			<div class="px-4 py-5 sm:p-0">
				<dl>
					<div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
						<dt class="text-sm leading-5 font-medium text-gray-500">
							Licenses Users
						</dt>
						<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
							{{ members.length }} out of {{ subscription.seats }}
							<p class="mt-1 text-xs leading-5 text-gray-500">
								You can add more on the
								<router-link class="text-blue-500" :to="{ name: 'billing-settings' }">Billing</router-link>
								page
							</p>
						</dd>
					</div>
					<div class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
						<dt class="text-sm leading-5 font-medium text-gray-500">
							Licensed Users
						</dt>
						<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">
							<ul class="border border-gray-200 rounded-md">
								<li
									v-for="(member, i) in members"
									:key="member.email"
									class="pl-3 pr-4 py-3 flex items-center justify-between text-sm leading-5"
								>
									<div class="w-0 flex-1 flex items-center">
										<span class="ml-2 truncate">
											<!-- Add icon -->
											{{ member.email }}
										</span>
									</div>
									<div class="ml-4 flex-shrink-0">
										<template v-if="!confirm[i]">
											<button
												class="font-medium text-indigo-600 hover:text-indigo-500 transition duration-150 ease-in-out"
												@click="confirmDelete(i)"
											>
												Remove
											</button>
										</template>
										<template v-else>
											<button
												class="mr-3 inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-50 focus:outline-none focus:border-red-300 focus:shadow-outline-red active:bg-red-200 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
												icon="trash-alt"
												fad
												@click="deleteMembership(member.membershipId)"
											>
												Sure?
											</button>
											<button icon="ban" fad @click="cancelDelete(i)">Cancel</button>
										</template>
									</div>
								</li>
							</ul>
						</dd>
					</div>
					<div class="mt-8 sm:mt-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-t sm:border-gray-200 sm:px-6 sm:py-5">
						<dt class="text-sm leading-5 font-medium text-gray-500">
							Assign License Link
						</dt>
						<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-2">{{ signUpLink }}</dd>
					</div>
				</dl>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import firebase from '@/helpers/firebaseInit.js'
export default {
	name: 'LicenseSettings',
	data() {
		return {
			confirm: []
		}
	},
	computed: {
		...mapState('accounts', ['current']),
		subscription() {
			return this.current.subscription
		},
		members() {
			return Object.values(this.current.members)
		},
		signUpLink() {
			return `https://app.storism.io/account/add?n=${this.current._name}&k=${this.current.key}`
		}
	},
	methods: {
		confirmDelete(i) {
			this.$set(this.confirm, i, true)
		},
		cancelDelete(i) {
			this.$set(this.confirm, i, false)
		},
		deleteMembership(id) {
			var removeUserLicense = firebase.functions.httpsCallable('account-removeUserLicense')
			removeUserLicense(id)
		}
	}
}
</script>

<style></style>
